/* eslint-disable max-len */
// src/notifications/templates/mail-templates.ts

import { AppointmentEvent } from './appointment-event';

/**
 * Collection of mail templates in HTML
 * provides a standard getMailTemplate() method
 * generates html templated content (with key fields being enclosed in {{ }}, to be replaced during runtime)
 */

const htmlPage = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <title>Notification</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style>
      body { font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif }
      .notification-heading { display: flex; flex-direction: row; align-items: center;}
      .notification-logo { margin: 12px; width: 48px; height: 48px;}
      .notification-text { margin: 24px 72px; }
      .notification-sub-heading { color: red; }
    </style>
  </head>
<body> {{bodyPlaceholder}} </body>
</html>
`;

// TODO: insert Org Logo Url
/*
 <div>
   <img src="{{orgLogoUrl}}" width="96px" />
  </div>
 */

const htmlHeading = `
<div class="notification-heading">
  <div>
    <h3>{{orgName}} {{facility}}</h3>
    <div>{{facilityAddress}}</div>
    <div>{{facilityPhone}}</div>
  </div>
</div>
<hr/>
`;

const appointmentSchedule = `
  <div class="notification-text">
  <div style="font-weight: bold; font-size: 1.2rem; text-align: center">Your appointment is confirmed</div>
  <br />
  <div>Dear <b>{{patientName}}</b>,</div>
  <br />
  <div>A Medical-Legal evaluation appointment has been made on your behalf. Below are the details.</div>
    <ul>
      <li>Appointment Date and Time: {{appointment.date}} {{appointment.time}}</li>
      <li>Location: {{facility}} {{facilityAddress}} {{facilityUrl}}</li>
      <li>Phone Number: {{facilityPhone}}</li>
      <li>Notes: {{appointment.notes}}</li>
      <li>Type: {{appointment.type}}</li>
    </ul>
    <br />
    <div>Please arrive 15 minutes early for the appointment.</div>
    <br />
    <div>Please contact our office during normal office hours to reschedule, if you have questions or need to cancel the appointment.</div>
  </div>
`;
// <div>You can view this reminder online by visiting our secure <a href="https://portal.accidentmate.com">Patient Portal</a></div>

const appointmentReschedule = `
  <div class="notification-text">
  <div style="font-weight: bold; font-size: 1.2rem; text-align: center">Your appointment is rescheduled & confirmed</div>
  <div>Dear <b>{{patientName}}</b>,</div>
  <br />
  <div>Your Medical-Legal evaluation appointment has been rescheduled on your behalf. Below are the details.</div>
    <ul>
      <li>Rescheduled Appointment Date and Time: {{appointment.date}} {{appointment.time}}</li>
      <li>Location: {{facility}} {{facilityAddress}}</li>
      <li>Phone Number: {{facilityPhone}}</li>
      <li>Notes: {{appointment.notes}}</li>
      <li>Type: {{appointment.type}}</li>
    </ul>
    <br />
    <div>Please arrive 15 mins early for the appointment.</div>
    <br />
    <div>Please contact our office during normal office hours to reschedule, if you have questions or need to cancel the appointment.</div>
  </div>
`;
// <div>You can view this reminder online by visiting our secure <a href="https://portal.accidentmate.com">Patient Portal</a></div>

const appointmentNoShow = `
  <h3>You have missed your appointment</h3>
  <div class="notification-text">
    <div>Dear <b>{{patientName}},</b></div>
    <br />
    <div>You have missed your scheduled appointment on {{appointment.date}}, {{appointment.time}}  with {{appointment.with}} and is now marked as no-show.</div>
    <div>Please contact our office during normal office hours: {{facilityPhone}}, if you have questions or need to schedule a new appointment.</div>
  </div>
`;

const appointmentCancelled = `
  <div class="notification-text">
    <div>Dear <b>{{patientName}},</b></div>
    <br />
    <div>The scheduled appointment has been cancelled.</div>
    <div>Please contact our office during normal office hours: {{facilityPhone}}, if you have questions or need to schedule a new appointment.</div>
  </div>
`;

const appointmentScheduleSubject = `
    {{orgName}}: {{patientName}} - Your appointment with {{appointment.with}} is scheduled on {{appointment.date}}, {{appointment.time}}
    `;

const appointmentReScheduleSubject = `
    {{orgName}}: {{patientName}} - Your appointment with {{appointment.with}} is rescheduled to {{appointment.date}}, {{appointment.time}}
    `;

const appointmentNoShowSubject = '{{orgName}}: {{patientName}} You have missed the appointment with {{appointment.with}} ';

const appointmentCancelledSubject =
  '{{orgName}}:  {{patientName}} - Your appointment with {{appointment.with}} on {{appointment.date}}, {{appointment.time}} has been cancelled.';

// Template object with different mail body templated strings
const template = {
  [AppointmentEvent.APPOINTMENT_SCHEDULED]: appointmentSchedule,
  [AppointmentEvent.APPOINTMENT_CANCELLED]: appointmentCancelled,
  [AppointmentEvent.APPOINTMENT_RESCHEDULED]: appointmentReschedule,
  [AppointmentEvent.APPOINTMENT_NOSHOW]: appointmentNoShow,
};

const subjectTemplate = {
  [AppointmentEvent.APPOINTMENT_SCHEDULED]: appointmentScheduleSubject,
  [AppointmentEvent.APPOINTMENT_CANCELLED]: appointmentCancelledSubject,
  [AppointmentEvent.APPOINTMENT_RESCHEDULED]: appointmentReScheduleSubject,
  [AppointmentEvent.APPOINTMENT_NOSHOW]: appointmentNoShowSubject,
};

export function getMailTemplate(event: AppointmentEvent): string {
  const selectedBody = htmlHeading.concat(template[event]);
  const mailTemplate = htmlPage.replace(/{{bodyPlaceholder}}/g, selectedBody);
  return mailTemplate;
}

export function getMailSubject(event: AppointmentEvent): string {
  const selectedSubject = subjectTemplate[event];
  return selectedSubject;
}
