/**
 * Appointment data model
 */

export enum AppointmentEvent {
  APPOINTMENT_SCHEDULED,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_RESCHEDULED,
  APPOINTMENT_NOSHOW,
}

export interface AppointmentDetails {
  orgName?: string;
  with?: string;
  facility?: string;
  facilityAddress?: string;
  facilityUrl?: string;
  facilityPhone?: string;
  start?: string;
  name?: string;
  details?: string;
  email?: string;
  notes?: string;
  type?: string;
}
