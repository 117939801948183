/**
 * Patient data model
 */
import { DefendantInsurance, PlaintiffInsurance, WorkCompInsurance } from './accident';

export interface HealthInsurance {
  name: string;
  policyNo?: string;
  phone?: string;
  fax?: string;
  mailingAddressStreet?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZip?: string;
}

export interface AlternateContactOptions {
  answeringMachine?: boolean;
  cellPhone?: boolean;
  employmentPhone?: boolean;
  voiceMail?: boolean;
  familyVoiceMail?: boolean;
  friendVoiceMail?: boolean;
  coWorkerVoiceMail?: boolean;
  familyEmail?: boolean;
  friendEmail?: boolean;
  coWorkerEmail?: boolean;
}

export interface AlternateContactDetail {
  name?: string;
  relationship?: string;
  phone?: string;
  email?: string;
}

export interface Patient {
  title?: string;
  email?: string;
  name?: string;
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  ssn?: string;
  dob: Date;
  caseType?: string;
  caseCategory?: string;
  doi?: Date;
  // cumulative trauma (repetitive stress injury) - if true capture doiEnd date
  ct?: boolean;
  doiEnd?: Date;
  bodyParts?: Array<string>;
  org?: string;
  treatingDoctor?: string;
  healthInsurance?: HealthInsurance;
  plaintiffAutoInsurance?: PlaintiffInsurance;
  defAutoInsurance?: DefendantInsurance;
  workCompInsurance?: WorkCompInsurance;
  attorney?: any;
  photoUrl?: string;
  gender?: string;
  language?: string;
  interpreter?: string;
  status?: number;
  address?: string;
  cellPhone?: string;
  otherPhone?: string;
  maritalStatus?: string;
  emergencyContacts?: string;
  driversLicense?: string;
  alternateContactInfo?: {
    details?: AlternateContactDetail[];
    options?: AlternateContactOptions;
  };
  referredBy?: {
    name?: string;
    phone?: string;
    email?: string;
    fax?: string;
    address?: string;
    referredOn?: Date | string;
  };
  [prop: string]: any;
} // TODO: Redefine the Patient Domain Object properly

/**
 * Patient Statuses
 * Statuses can transition:
 * starts with NEW --> ACTIVE
 * ACTIVE <--> INACTIVE
 * INACTIVE --> ARCHIVED
 */
export enum PatientStatus {
  NEW,
  ACTIVE,
  INACTIVE,
  ARCHIVED,
}

export interface Note {
  id?: string;
  date?: string;
  description?: string;
  author?: string;
  type?: string;
  sensitive?: boolean;
}

export interface AuditLog extends Note {
  previous?: any;
  current?: any;
  [key: string]: any;
}

export enum PatientDataEvents {
  NEW_INTAKE,
  PATIENT_DATA_UPDATED,
  APPOINTMENT_SCHEDULED,
  APPOINTMENT_CHECKED_IN,
  APPOINTMENT_COMPLETED,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_LATE_CANCELLED,
  APPOINTMENT_RESCHEDULED,
  APPOINTMENT_NOSHOW,
  APPOINTMENT_UNDO_NOSHOW,
  APPOINTMENT_UPDATED,
  SOAP_DATA_UPDATED,
  OFFICE_FORMS_SENT,
  SUPERBILL_UPDATED,
  RECORD_REVIEW_ORDERED,
  HISTORY_ORDERED,
  HISTORY_COMPLETED,
  HISTORY_CANCELLED,
  BILLING_ORDERED,
  INTAKE_COVER_PRINTED,
  DWC_FORM_PRINTED,
  REPORT_GENERATED,
  // TODO: Add more
}

export const patientDataEventDescriptions = [
  'Patient Intake Done',
  'Patient data updated.',
  'New appointment scheduled.',
  'Patient checked-in.',
  'Patient checked-out/visit completed.',
  'Appointment cancelled!',
  'Appointment LATE cancelled!',
  'Appointment rescheduled.',
  'Patient appointment: NO SHOW!',
  'Patient appointment reverted from NO SHOW',
  'Appointment updated.',
  'SOAP data updated.',
  'Office form(s) sent to Patient.',
  'Superbill updated.',
  'Record Review ordered.',
  'History ordered.',
  'History completed.',
  'History cancelled.',
  'Billing ordered.',
  'Intake cover printed.',
  'DWC form printed.',
  'Report generated.',
];
